var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.expiringDeal)?_c('b-modal',{ref:"expiringDealsEditorModal",attrs:{"lazy":"","id":"expiringDealsEditorModalId","size":"md","ok-title":"Save","cancel-title":"Cancel","title":"Expiring deals filter editor"},on:{"hidden":_vm.handleCancel,"ok":_vm.handleOk},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[(!_vm.busy)?_c('div',[(_vm.errorMessage)?_c('b-button',{staticClass:"w-100 mb-2",attrs:{"disabled":"","pill":"","variant":"danger"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('label',[_vm._v("Last Updated:")]),(
            _vm.expiringDeal &&
            _vm.expiringDeal.expiringDealFilter &&
            _vm.expiringDeal.expiringDealFilter.lastUpdated
          )?_c('p',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.expiringDeal.expiringDealFilter.lastUpdated))+" ")]):_c('p',[_vm._v("No Data")])]),_c('b-col',{attrs:{"cols":"7"}},[_c('label',[_vm._v("Last Updated By:")]),(
            _vm.expiringDeal &&
            _vm.expiringDeal.expiringDealFilter &&
            _vm.expiringDeal.expiringDealFilter.lastUpdatedBy
          )?_c('p',[_vm._v(" "+_vm._s(_vm.expiringDeal.expiringDealFilter.lastUpdatedBy)+" ")]):_c('p',[_vm._v("No Data")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-input-group',[_c('b-select',{staticClass:"mb-2 mr-2",attrs:{"options":_vm.reasons},model:{value:(_vm.selectedReason),callback:function ($$v) {_vm.selectedReason=$$v},expression:"selectedReason"}}),_c('b-input-group-button',[_c('b-button',{attrs:{"variant":"danger","title":"Remove filter"},on:{"click":function($event){return _vm.deleteFilter()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash']}})],1)],1)],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.notes),expression:"notes"}],staticClass:"w-100 p-2",attrs:{"id":"notesTextArea","placeholder":"Write your notes"},domProps:{"value":(_vm.notes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.notes=$event.target.value}}})],1)],1)],1):_c('div',[_c('Busy',{attrs:{"primary":"","text":'Saving'}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }