<template>
  <div class="mx-4" id="expiring-deals-view">
    <div>
      <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

      <ExpiringDealEditorModal
        :expiringDeal="openExpiringDeal"
        @cancelled="openExpiringDeal = null"
      ></ExpiringDealEditorModal>

      <b-container fluid>
        <h1 class="mw-50">Expiring Deals</h1>

        <!-- Search section -->
        <b-form-group class="mb-1" @keydown.native.esc="clearSearch">
          <div class="search-filters mb-2">
            <b-row>
              <b-col cols="12" md="3">
                <label>Contracts Expiring From:</label>
                <b-input id="startDate" class="mb-2" v-model="startDate" type="date" />
                <label>Contracts Expiring To:</label>
                <b-input id="endDate" class="mb-2" v-model="endDate" type="date" />
              </b-col>
              <b-col cols="12" md="3">
                <label>Recommended Last Updated From:</label>
                <b-input
                  id="updatedStartDate"
                  class="mb-2"
                  v-model="updatedStartDate"
                  type="date"
                />
                <label>Recommended Last Updated To:</label>
                <b-input id="updatedEndDate" class="mb-2" v-model="updatedEndDate" type="date" />
              </b-col>
              <b-col cols="12" md="3">
                <label>Min. Commission:</label>
                <b-input id="minComm" class="mb-2" v-model="minComm" type="number" />
                <label>Max Commission:</label>
                <b-input id="maxComm" class="mb-2" v-model="maxComm" type="number" />
              </b-col>
              <b-col cols="12" md="3">
                <label>Min. Saving:</label>
                <b-input id="minSavings" class="mb-2" v-model="minSavings" type="number" />
                <label>Max. Saving:</label>
                <b-input id="maxSavings" class="mb-2" v-model="maxSavings" type="number" />
              </b-col>
            </b-row>

            <!-- Backend filters-->
            <b-row>
              <b-col cols="12" xl="2" class="mt-2">
                <!-- Select Type -->
                <b-dropdown id="filterDropdown" variant="primary" class="mr-2" text="Categories">
                  <b-dropdown-item
                    @click="appendCategoryToSelected(option)"
                    v-for="option in options"
                    v-bind:key="option.value"
                  >
                    <span :id="option.value">{{ supplyTypeDisplay(option.value) }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>

              <b-col cols="12" xl="10" class="mt-2">
                <b-checkbox v-model="filterDemoCompanies" class="mr-2 mt-2 d-inline-block"
                  >Exclude&nbsp;Demo&nbsp;Orgs</b-checkbox
                >

                <b-checkbox v-model="filterReducerEmails" class="mr-2 mt-2 d-inline-block"
                  >Exclude&nbsp;Reducer&nbsp;Users</b-checkbox
                >

                <b-checkbox
                  v-model="filterExpiringDeals"
                  @click="toggleEnableFilter()"
                  class="mt-2 mr-2 d-inline-block"
                  >Exclude&nbsp;Deals&nbsp;With&nbsp;Filter</b-checkbox
                >

                <b-checkbox
                  v-model="filterOutOfContractDeals"
                  class="mr-2 mt-2 d-inline-block"
                  @change="setExcludeOOC()"
                >
                  Exclude
                  <b>OOC</b>
                </b-checkbox>

                <b-checkbox
                  v-model="onlyOutOfContractDeals"
                  class="mr-2 mt-2 d-inline-block"
                  @change="setOnlyOOC()"
                >
                  Show only
                  <b>OOC</b>
                </b-checkbox>

                <b-checkbox v-model="onlyActiveFreshsales" class="mr-2 mt-2 d-inline-block">
                  Show only
                  <b>Active&nbsp;in&nbsp;Freshsales</b>
                </b-checkbox>
              </b-col>
            </b-row>

            <!-- Categories selected list -->
            <b-row class="pt-3" v-if="selected.length > 0">
              <b-col>
                <div
                  class="categoryDiv d-inline"
                  @click="removeCategoryFromSelected(category)"
                  v-for="category in selected"
                  v-bind:key="category"
                >
                  <span class="categoriesAdded">
                    {{ supplyTypeDisplay(category) }}
                    <font-awesome-icon class="mr-2" :icon="['fas', 'times-circle']" />
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                <div class="ml-3 mt-2 d-inline-block">
                  <b-btn @click="fetchData" variant="primary" class="mr-2">Search</b-btn>
                  <b-btn @click="clearSearch" class="mr-2">Clear</b-btn>
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- Frontend filters -->
          <div class="result-filters">
            <b-row>
              <b-col cols="12" md="3" class="my-1">
                <b-form-input
                  v-model="filterOrganisationName"
                  placeholder="Organisation name filter"
                />
              </b-col>
              <b-col cols="12" md="3" class="my-1">
                <b-form-input v-model="filterSupplierName" placeholder="Supplier name filter" />
              </b-col>
              <b-col cols="12" md="3" class="my-1">
                <b-form-input v-model="filterFirstUser" placeholder="First User filter" />
              </b-col>
              <b-col cols="12" md="3" class="my-1">
                <b-checkbox v-model="filterQuoted" class="mr-2 mt-2 d-inline-block"
                  >Exclude&nbsp;Deals&nbsp;With&nbsp;Offers</b-checkbox
                >
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col>
                <b-form-group class="p-0 m-0">
                  <b-checkbox
                    id="filterNoQuotes"
                    @change="toggleFilterNoQuotes()"
                    class="mr-5 d-inline-block"
                    switch
                  >
                    Exclude No Quotes
                  </b-checkbox>
                  <label class="mr-2">No Quotes:</label>
                  <b-form-checkbox-group class="d-inline-block" v-model="noQuoteFilters">
                    <b-form-checkbox :disabled="excludeNoQuotes" value="IN_CONTRACT"
                      >In Contract</b-form-checkbox
                    >
                    <b-form-checkbox :disabled="excludeNoQuotes" value="NOT_ENOUGH_DATA"
                      >Not Enough Data</b-form-checkbox
                    >
                    <b-form-checkbox :disabled="excludeNoQuotes" value="ON_GOOD_DEAL"
                      >On A Good Deal</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-1" v-if="!filterExpiringDeals">
              <b-col>
                <b-form-group class="p-0 m-0">
                  <label class="mr-2">Filter Reasons:</label>
                  <b-form-checkbox-group class="d-inline-block" v-model="customFilters">
                    <b-form-checkbox value="ACCOUNTANT">Accountant</b-form-checkbox>
                    <b-form-checkbox value="NOT_SHARED">Not shared</b-form-checkbox>
                    <b-form-checkbox value="RESIGNED">Resigned</b-form-checkbox>
                    <b-form-checkbox value="NOT_INTERESTED">Not interested</b-form-checkbox>
                    <b-form-checkbox value="CANNOT_CONTACT">Cannot contact</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-form-group>

        <b-row class="pagination pt-2" align-v="end">
          <b-col class="mb-2">
            <p class="mb-1">
              <span class="mr-2">Deals count: {{ filteredExtendedDeals.length }}</span>
              <span class="mr-2"
                >Deal Savings: {{ $currencySymbol + formatNumberToDisplay(savingsSum) }}</span
              >
              <span
                >Deal&nbsp;Commission:
                {{ $currencySymbol + formatNumberToDisplay(commissionSum) }}</span
              >
            </p>
            <p class="mb-1">
              <span class="mr-2">Org count: {{ orgCount }}</span>
              <span class="mr-2"
                >Org Savings: {{ $currencySymbol + formatNumberToDisplay(orgSavingsSum) }}</span
              >
              <span
                >Org&nbsp;Commission:
                {{ $currencySymbol + formatNumberToDisplay(orgCommissionSum) }}</span
              >
            </p>
            <b-button v-if="filteredExtendedDeals.length > 0" @click="openInSalesBible" size="sm"
              >View in Sales Bible <font-awesome-icon :icon="['fas', 'external-link']" class="ml-1"
            /></b-button>
          </b-col>
          <b-col class="text-right">
            <p class="mb-2">
              <span>Deals per page:</span>
              <span class="ml-1">
                <span v-if="perPage == 50">50</span>
                <b-link v-else @click="perPage = 50">50</b-link>,
                <span v-if="perPage == 100">100</span>
                <b-link v-else @click="perPage = 100">100</b-link>,
                <span v-if="perPage == 500">500</span>
                <b-link v-else @click="perPage = 500">500</b-link>,
                <span v-if="perPage == allItems">All</span>
                <b-link v-else @click="perPage = allItems">All</b-link>
              </span>
            </p>

            <b-pagination
              class="float-right"
              v-show="filteredExtendedDeals.length > perPage"
              v-model="currentPage"
              :total-rows="filteredExtendedDeals.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </b-col>
        </b-row>

        <b-row id="expiring-deals-section">
          <b-col>
            <b-table
              busy.sync="loading"
              show-empty
              :items="filteredExtendedDeals"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template slot="empty">
                <div v-if="loading" class="text-center">
                  <Busy primary text="Loading..." />
                </div>
                <div v-else class="text-center">Search for deals to show.</div>
              </template>

              <!-- Action Button -->
              <template v-slot:cell(actionButton)="row">
                <b-dropdown lazy size="sm">
                  <b-dropdown-item
                    :to="{
                      name: 'organisation-details',
                      params: { organisationId: row.item.organisationId }
                    }"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'info-circle']"
                      class="text-muted mr-2"
                    />Organisation Details
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="row.item.reportCreated"
                    :to="{
                      name: 'savings',
                      params: { organisationId: row.item.organisationId }
                    }"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'info-circle']"
                      class="text-muted mr-2"
                    />Savings Report
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="isAdmin"
                    :to="{
                      name: 'savings-editor',
                      params: {
                        organisationId: row.item.organisationId,
                        latestSupply: [row.item.type, {}]
                      }
                    }"
                  >
                    <font-awesome-icon :icon="['fas', 'unicorn']" class="text-muted mr-2" />Savings
                    editor
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{
                      name: 'spotlight',
                      params: { organisationId: row.item.organisationId }
                    }"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'hand-spock']"
                      class="text-muted mr-2"
                    />Spotlight
                  </b-dropdown-item>
                  <b-dropdown-item @click="toExpiringDealModal(row.item)">
                    <font-awesome-icon :icon="['far', 'edit']" class="text-muted mr-2" />Edit filter
                  </b-dropdown-item>
                </b-dropdown>
              </template>

              <!-- Contract expiry -->
              <template v-slot:cell(contractExpiry)="row">
                <span v-if="row.item.outOfContract == 0">{{
                  dateFormatter(row.item.contractExpiry)
                }}</span>
                <span v-else v-text="'OOC'"></span>
              </template>

              <!-- Updated -->
              <template v-slot:cell(recommendedUpdatedDaysAgo)="row">
                <span v-if="row.item.recommendedLastUpdated">{{
                  dateFormatter(row.item.recommendedLastUpdated)
                }}</span>
                <div v-if="!isNaN(row.item.recommendedUpdatedDaysAgo)" class="reducer-badge">
                  <b-badge v-if="row.value === 0" pill>Today</b-badge>
                  <b-badge v-else pill>{{
                    row.value + (row.value > 1 ? " days ago" : " day ago")
                  }}</b-badge>
                </div>
              </template>

              <!-- Report/Deal created -->
              <template v-slot:cell(dealCreated)="row">
                <span v-if="row.item.reportCreated">{{
                  dateFormatter(row.item.reportCreated)
                }}</span>
                <span v-else>{{ row.value }}</span>
              </template>

              <!-- Freshsales -->
              <template v-slot:cell(activeFreshsales)="row">
                <span v-if="row.item.activeFreshsales == true">
                  <font-awesome-icon :icon="['fas', 'check-circle']" />
                </span>
              </template>

              <!-- Filter -->

              <template v-slot:cell(expiringDealFilter)="row">
                <span
                  v-if="row.item.expiringDealFilter"
                  v-b-popover.hover.top.window="
                    row.item.expiringDealFilter.notes
                      ? row.item.expiringDealFilter.notes
                      : 'No notes'
                  "
                  >{{ formatCoboldCase(row.item.expiringDealFilter.reason) }}</span
                >
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-pagination
          class="float-right"
          v-show="filteredExtendedDeals.length > perPage"
          v-model="currentPage"
          :total-rows="filteredExtendedDeals.length"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#expiring-deals-view {
  #expiring-deals-section {
    min-height: 70vh;
  }

  #filterDropdown,
  #filterDropdown > .dropdown-menu {
    width: 100%;
  }

  .fa-times-circle {
    cursor: pointer;
  }

  .categoryDiv {
    cursor: pointer;
  }

  .categoriesAdded {
    position: relative;
    border: 1px solid $color-font-para;
    padding: 8px;
    border-radius: 5px;
    margin-right: 4px;
    color: $color-font-para;
  }

  .search-filters {
    background-color: $color-grey-lighter4;
    padding: 1rem;
  }
  .result-filters {
    background-color: $color-grey-lighter4;
    padding: 1rem;
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import { EventBus } from "@/components/eventbus";
import CognitoAuth from "@/cognito/cognitoauth";
import FormatHelper from "@/helper/formathelper";
import SupplyTypes from "@/helper/supplytypehelper";
import Busy from "@/components/Busy";
import ExpiringDealEditorModal from "@/components/ExpiringDealEditorModal";

export default {
  name: "ExtendedDeal",
  components: { Busy, ExpiringDealEditorModal },
  data() {
    return {
      title: "Expiring Deals",
      isAdmin: false,
      loading: true,

      tableSpinner: true,
      openExpiringDeal: null,

      //Table of filtered results
      perPage: 50,
      currentPage: 1,
      fields: [
        { key: "actionButton", label: "", class: "text-center align-middle" },
        {
          key: "organisationName",
          label: "Org. Name",
          formatter: "organisationFormatter",
          sortable: true
        },
        {
          key: "type",
          label: "Type",
          formatter: "categoryFomatter",
          sortable: true
        },
        {
          key: "dealCreated",
          label: "Created",
          formatter: "dateFormatter",
          sortable: true
        },
        {
          key: "recommendedUpdatedDaysAgo",
          label: "Rec. Updated",
          sortable: true
        },
        { key: "supplierName", label: "Supplier", sortable: true },
        {
          key: "dayConsumption",
          label: "AQ",
          sortDirection: "desc",
          sortable: true
        },
        {
          key: "annualCost",
          label: "Annual Cost",
          formatter: "costFormatter",
          sortDirection: "desc",
          sortable: true
        },
        {
          key: "contractExpiry",
          label: "Contract Expiry",
          sortable: true
        },
        {
          key: "totalSavings",
          label: "Org. Savings",
          formatter: "costFormatter",
          sortDirection: "desc",
          sortable: true
        },
        {
          key: "totalCommission",
          label: "Org. Comm.",
          formatter: "costFormatter",
          sortDirection: "desc",
          sortable: true
        },
        {
          key: "supplySaving",
          label: "Savings",
          formatter: "costFormatter",
          sortDirection: "desc",
          sortable: true
        },
        {
          key: "supplyCommission",
          label: "Comm.",
          formatter: "costFormatter",
          sortDirection: "desc",
          sortable: true
        },
        {
          key: "supplyNoQuote",
          label: "No Quote",
          sortDirection: "desc",
          sortable: true,
          formatter: "formatCoboldCase"
        },

        {
          key: "expiringDealFilter",
          formatter: "expiringDealFilterFormatter",
          label: "Filter",
          sortable: true
        },
        { key: "firstUserEmail", label: "First User" },
        { key: "activeFreshsales", label: "FS" }
      ],
      extendedDeals: [],

      //Types Dropdown
      options: [],
      selected: [],
      startDate: "",
      endDate: "",
      updatedEndDate: "",
      updatedStartDate: "",
      minComm: "",
      maxComm: "",
      minSavings: "",
      maxSavings: "",
      filterSupplierName: null,
      filterOrganisationName: null,
      filterFirstUser: null,
      customFilters: [],
      noQuoteFilters: [],
      filterDemoCompanies: true,
      filterReducerEmails: true,
      filterOutOfContractDeals: false,
      filterExpiringDeals: true,
      onlyOutOfContractDeals: false,
      onlyActiveFreshsales: false,
      filterQuoted: false,
      excludeNoQuotes: false,
      expiringdealsModal: [],
      allItems: ApiHelper.ALL_ITEMS
    };
  },

  created() {
    this.supplyTypes().forEach(element => {
      this.options.push({ value: element });
    });

    this.loading = false;
  },

  computed: {
    filteredExtendedDeals() {
      let result = this.extendedDeals;

      if (this.filterSupplierName && this.filterSupplierName.length > 1) {
        result = result.filter(extendedDeal => {
          if (typeof extendedDeal.supplierName != "undefined") {
            return extendedDeal.supplierName
              .toLowerCase()
              .includes(this.filterSupplierName.toLowerCase());
          }
        });
      }

      if (this.filterOrganisationName && this.filterOrganisationName.length > 1) {
        result = result.filter(extendedDeal => {
          if (typeof extendedDeal.organisationName != "undefined") {
            return extendedDeal.organisationName
              .toLowerCase()
              .includes(this.filterOrganisationName.toLowerCase());
          }
        });
      }

      if (this.filterQuoted) {
        result = result.filter(extendedDeal => {
          if (typeof extendedDeal.supplySaving == "undefined") {
            return true;
          }
        });
      }

      if (this.filterFirstUser && this.filterFirstUser.length > 1) {
        result = result.filter(extendedDeal => {
          if (typeof extendedDeal.firstUserEmail != "undefined") {
            return extendedDeal.firstUserEmail
              .toLowerCase()
              .includes(this.filterFirstUser.toLowerCase());
          }
        });
      }

      if (this.excludeNoQuotes) {
        result = result.filter(extendedDeal => {
          if (!extendedDeal.supplyNoQuote) {
            return true;
          }
        });
      } else if (this.noQuoteFilters && this.noQuoteFilters.length > 0) {
        result = result.filter(extendedDeal => {
          if (extendedDeal.supplyNoQuote) {
            return this.noQuoteFilters.includes(extendedDeal.supplyNoQuote);
          }
        });
      }

      if (this.customFilters && this.customFilters.length > 0) {
        this.customFilters.forEach(customFilter => {
          result = result.filter(extendedDeal => {
            if (extendedDeal.expiringDealFilter && extendedDeal.expiringDealFilter.reason) {
              return (
                extendedDeal.expiringDealFilter.reason.toLowerCase() == customFilter.toLowerCase()
              );
            }
          });
        });
      }

      return result;
    },

    orgCount() {
      return this.filteredExtendedDeals.reduce(
        (acc, x) => acc.concat(acc.find(y => y.organisationName === x.organisationName) ? [] : [x]),
        []
      ).length;
    },

    savingsSum() {
      return this.filteredExtendedDeals.reduce(function(a, b) {
        return a + (b["supplySaving"] ? b["supplySaving"] : 0);
      }, 0);
    },

    commissionSum() {
      return this.filteredExtendedDeals.reduce(function(a, b) {
        return a + (b["supplyCommission"] ? b["supplyCommission"] : 0);
      }, 0);
    },

    orgSavingsSum() {
      const uniqueOrgs = this.filteredExtendedDeals.reduce(
        (acc, x) => acc.concat(acc.find(y => y.organisationName === x.organisationName) ? [] : [x]),
        []
      );
      return uniqueOrgs.reduce(function(a, b) {
        return a + (b["totalSavings"] ? b["totalSavings"] : 0);
      }, 0);
    },

    orgCommissionSum() {
      const uniqueOrgs = this.filteredExtendedDeals.reduce(
        (acc, x) => acc.concat(acc.find(y => y.organisationName === x.organisationName) ? [] : [x]),
        []
      );
      return uniqueOrgs.reduce(function(a, b) {
        return a + (b["totalCommission"] ? b["totalCommission"] : 0);
      }, 0);
    }
  },
  mounted() {
    this.options.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
  },
  methods: {
    supplyTypes: SupplyTypes.supplyTypes,
    supplyTypeDisplay: SupplyTypes.toDisplay,
    formatDate: FormatHelper.formatDate,
    formatNumberToDisplay: FormatHelper.formatNumberToDisplay,
    orgShortName: FormatHelper.orgShortName,
    formatCoboldCase: FormatHelper.formatCoboldCase,
    formatDateStr: FormatHelper.formatDateStringWithTime,

    setExcludeOOC() {
      if (this.filterOutOfContractDeals) {
        this.onlyOutOfContractDeals = false;
      }
    },

    setOnlyOOC() {
      if (this.onlyOutOfContractDeals) {
        this.filterOutOfContractDeals = false;
      }
    },

    async fetchData() {
      this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
      this.extendedDeals = [];
      this.loading = true;

      var startDateTemp = this.startDate;
      var endDateTemp = this.endDate;

      let query =
        `types=${this.selected}` +
        `&startDate=${this.formatDateStr(this.startDate)}` +
        `&endDate=${this.formatDateStr(this.endDate)}` +
        `&updatedStartDate=${this.formatDateStr(this.updatedStartDate)}` +
        `&updatedEndDate=${this.formatDateStr(this.updatedEndDate)}` +
        `&minComm=${this.minComm}` +
        `&maxComm=${this.maxComm}` +
        `&minSavings=${this.minSavings}` +
        `&maxSavings=${this.maxSavings}` +
        `&filterDemoCompanies=${this.filterDemoCompanies}` +
        `&filterReducerEmails=${this.filterReducerEmails}` +
        `&filterOutOfContractDeals=${this.filterOutOfContractDeals}` +
        `&onlyOutOfContractDeals=${this.onlyOutOfContractDeals}` +
        `&onlyActiveFreshsales=${this.onlyActiveFreshsales}` +
        `&filterExpiringDeals=${this.filterExpiringDeals}`;

      try {
        const client = await ApiHelper.http();
        const response = await client.get(`${ApiHelper.endPoint()}deals/expiringdeals?` + query);
        if (response.data.status === "success") {
          this.extendedDeals = response.data.deals;
          Console.log("Expiring deals: ", response.data.deals);
        } else {
          this.showMessage("A problem occured in searching for data.", "warning");
        }
      } catch (error) {
        Console.error(error);
        this.showMessage("A problem occured in searching for data.", "warning");
      } finally {
        this.loading = false;
        this.startDate = startDateTemp;
        this.endDate = endDateTemp;
        this.extendedDeals.forEach(deal => {
          this.expiringdealsModal[deal.dealId] = deal;
        });
      }
    },

    //FILTER BAR AND DROPDOWN
    appendCategoryToSelected(option) {
      let index = this.options.indexOf(option);
      this.options.splice(index, 1);
      this.selected.push(option.value);
    },

    removeCategoryFromSelected(category) {
      this.options.push({ value: category });
      this.options.sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });

      this.selected.splice(this.selected.indexOf(category), 1);
    },

    clearSearch() {
      this.filterOrganisationName = "";
      this.filterSupplierName = "";
      this.filterFirstUser = "";
      this.customFilters = [];

      this.selected.forEach(element => {
        this.options.push({ value: element });
      });
      this.options.sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });

      this.selected = [];
      this.endDate = "";
      this.startDate = "";
      this.minComm = "";
      this.maxComm = "";
      this.minSavings = "";
      this.maxSavings = "";
      this.filterReducerEmails = true;
      this.filterOutOfContractDeals = false;
      this.filterDemoCompanies = true;
      this.onlyOutOfContractDeals = false;
      this.onlyActiveFreshsales = false;
    },

    categoryFomatter(value) {
      if (value) {
        return this.supplyTypeDisplay(value);
      } else {
        ("");
      }
    },

    dateFormatter(value) {
      if (value) {
        return FormatHelper.formatDateFullYear(value);
      } else {
        return "";
      }
    },

    costFormatter(value) {
      if (value) {
        return this.$currencySymbol + FormatHelper.formatNumberToDisplay(value);
      } else {
        return "";
      }
    },

    contractTermFormatter(value) {
      if (value) {
        return FormatHelper.formatMonthsToYears(value);
      } else {
        return "";
      }
    },

    organisationFormatter(value) {
      if (value) {
        return this.orgShortName(value);
      } else {
        return "";
      }
    },

    copySuccess() {
      this.showMessage("URL copied successfully.", "success");
    },
    copyError() {
      this.showMessage("Couldn't copy URL.", "warning");
    },

    //MESSAGE FORMAT
    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    toExpiringDealModal(deal) {
      this.openExpiringDeal = deal;
    },

    closeExiringDealModal() {
      this.openExpiringDeal = null;
    },

    expiringDealFilterFormatter(value) {
      if (value && value.reason) {
        return value.reason
          .toLowerCase()
          .split("_")
          .map(function(word) {
            return word[0].toUpperCase() + word.substr(1);
          })
          .join(" ");
      } else {
        return null;
      }
    },

    toggleEnableFilter() {
      if (this.filterExpiringDeals) {
        this.customFilters = [];
      }
    },
    toggleFilterNoQuotes() {
      this.excludeNoQuotes = !this.excludeNoQuotes;
      if (this.excludeNoQuotes) {
        this.noQuoteFilters = [];
      }
    },

    openInSalesBible() {
      this.$router.push({
        name: "sales",
        params: {
          organisationIds: [...new Set(this.filteredExtendedDeals.map(d => d.organisationId))]
        }
      });
    }
  }
};
</script>
