<template>
  <b-modal
    v-if="expiringDeal"
    lazy
    v-model="isOpen"
    ref="expiringDealsEditorModal"
    id="expiringDealsEditorModalId"
    size="md"
    @hidden="handleCancel"
    @ok="handleOk"
    ok-title="Save"
    cancel-title="Cancel"
    title="Expiring deals filter editor"
  >
    <div v-if="!busy">
      <b-button disabled v-if="errorMessage" pill variant="danger" class="w-100 mb-2">{{
        errorMessage
      }}</b-button>

      <b-row>
        <b-col cols="5">
          <label>Last Updated:</label>
          <p
            v-if="
              expiringDeal &&
              expiringDeal.expiringDealFilter &&
              expiringDeal.expiringDealFilter.lastUpdated
            "
          >
            {{ formatDate(expiringDeal.expiringDealFilter.lastUpdated) }}
          </p>
          <p v-else>No Data</p>
        </b-col>
        <b-col cols="7">
          <label>Last Updated By:</label>
          <p
            v-if="
              expiringDeal &&
              expiringDeal.expiringDealFilter &&
              expiringDeal.expiringDealFilter.lastUpdatedBy
            "
          >
            {{ expiringDeal.expiringDealFilter.lastUpdatedBy }}
          </p>
          <p v-else>No Data</p>
        </b-col>

        <b-col cols="12">
          <b-input-group>
            <b-select class="mb-2 mr-2" v-model="selectedReason" :options="reasons"></b-select>
            <b-input-group-button>
              <b-button variant="danger" title="Remove filter" @click="deleteFilter()">
                <font-awesome-icon :icon="['fas', 'trash']" />
              </b-button>
            </b-input-group-button>
          </b-input-group>
          <textarea
            id="notesTextArea"
            class="w-100 p-2"
            placeholder="Write your notes"
            v-model="notes"
          ></textarea>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <Busy primary :text="'Saving'" />
    </div>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#notesTextArea {
  min-height: 70%;
}
</style>

<script>
import FormatHelper from "@/helper/formathelper";
import Console from "@/console";
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";

export default {
  name: "ExpiringDealEditorModal",
  props: {
    expiringDeal: Object
  },
  components: { Busy },
  computed: {
    isOpen: function () {
      /* eslint-disable */
      if (this.expiringDeal) {
        if (!this.expiringDeal.expiringDealFilter || !this.expiringDeal.expiringDealFilter.reason) {
          this.expiringDeal.expiringDealFilter = {};
          this.selectedReason = null;
          this.notes = null;
          this.errorMessage = null;
        } else {
          this.selectedReason = this.expiringDeal.expiringDealFilter.reason;
          this.notes = this.expiringDeal.expiringDealFilter.notes;
        }
        return true;
      } else {
        this.errorMessage = null;
        return false;
      }
    }
  },
  data() {
    return {
      reasons: [
        { value: null, text: "Select a reason" },
        { value: "NOT_SHARED", text: "Not Shared" },
        { value: "ACCOUNTANT", text: "Accountant" },
        { value: "RESIGNED", text: "Resigned" },
        { value: "NOT_INTERESTED", text: "Not Interested" },
        { value: "CANNOT_CONTACT", text: "Cannot contact" }
      ],
      selectedReason: null,
      notes: null,
      errorMessage: null,
      busy: false
    };
  },

  methods: {
    formatDate: FormatHelper.formatDate,
    formatCoboldCase: FormatHelper.formatCoboldCase,

    handleCancel() {
      this.$emit("cancelled");
    },

    deleteFilter() {
      this.expiringDeal.expiringDealFilter = null;
      this.errorMessage = null;

      this.saveFilter();
    },

    async handleOk(evt) {
      evt.preventDefault();
      this.errorMessage = null;

      if (!this.selectedReason && !this.expiringDeal.expiringDealFilter.notes) {
        this.errorMessage = "Notes cannot be saved without selecting a reason";
        return;
      }

      this.expiringDeal.expiringDealFilter.reason = this.selectedReason;
      this.expiringDeal.expiringDealFilter.notes = this.notes;

      this.saveFilter();
    },

    async saveFilter() {
      this.busy = true;

      try {
        const client = await ApiHelper.http();
        const response = await client.post(
          `${ApiHelper.endPoint()}deals/expiringdeals/${this.expiringDeal.organisationId}`,
          {
            expiringDealFilter: this.expiringDeal.expiringDealFilter,
            sortKey: this.expiringDeal.sortKey
          }
        );
        if (response.data.status === "success") {
          this.$emit("cancelled");
        } else {
          this.errorMessage = "A problem occured in searching for data.";
        }
      } catch (error) {
        Console.error(error);
        this.errorMessage = "A problem occured in searching for data.";
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>